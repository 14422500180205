import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { IconButton } from "@mui/material";
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete'
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Grid } from "@mui/material";
import Swal from 'sweetalert2'

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ae from '../assests/AEROPUERTOS.png'
import cli from '../assests/CLIENTES.png'
import equ from '../assests/EQUIPOS.png'
import inc from '../assests/INCIDENCIAS.png'
import mec from '../assests/MECANICOS.png'
import ref from '../assests/REFACCIONES.png'
import res from '../assests/RESUMEN.png'
import usu from '../assests/USUARIOS.png'





const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});
function getSum(total, num) {
  return total + num;
}
function Aeropuerto() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [value, setValue] = React.useState(dayjs('2022-04-17'));

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  

  const [data, setData] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [isNew, setIsnew] = useState(true);

  const [nombre, setNombre] = useState('')
  const [siglas, setSiglas] = useState('')
  const [idToEdit, setIdToEdit] = useState(0)

  const [clientes, setClientes] = useState([]);
  const [aeropuertos, setAeropuertos] = useState([]);
  const [equipos, setEquipos] = useState([]);

  const [cliente, setCliente] = useState(0);
  const [aeropuerto, setAeropuerto] = useState(0);
  const [equipo, setEquipo] = useState(0);

  const [ventas, setVentas] = useState(0)
  const [costos, setCostos] = useState(0);

  const [clienteId, setClienteId] = useState(0)
  const [idCliente, setIdCliente] = useState(0)
  const [idEquipo, setIdEquipo] = useState(0)
  const [idAeropuerto, setIdAeropuerto] = useState(0)
  

  const navigate = useNavigate();



  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>

      {/*<Box sx={{ width: `calc(100% - ${240}px)`, marginLeft: { sm: '260px' }, marginBottom: '20px'}}>
        <Typography variant="h4" >
            Bienvenido!
          </Typography>
          <Typography variant="h4" >
            Operaciones disponibles
          </Typography>
          
          </Box>
          */}
      
    <Box sx={{ width: `calc(100% - ${300}px)`, marginLeft: { sm: '260px' }, marginBottom: '50px'}}>
    
        <Grid container spacing={1} style={{marginTop: '30px', marginBottom: '50px', cursor: 'pointer'}}  justifyContent={'space-between'}>
          
        <Grid item container md={3} onClick={() => navigate('/resumen')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'} >
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Resumen
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={res} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
          <Grid item container md={4} onClick={() => navigate('/clientes')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'}>
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Clientes
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={cli} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
          <Grid item container md={3} onClick={() => navigate('/aeropuertos')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'}>
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Aeropuertos
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={ae} width={50} height={50} />
              </Grid>
              
          </Grid>
         

         
        </Grid>

        <Grid container spacing={1} style={{marginTop: '30px', marginBottom: '50px', cursor: 'pointer'}}  justifyContent={'space-between'}>
          
        <Grid item container md={3} onClick={() => navigate('/equipos')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'} >
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Equipos
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={equ} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
          <Grid item container md={4} onClick={() => navigate('/refacciones')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'}>
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Refacciones
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={ref} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
          <Grid item container md={3} onClick={() => navigate('/mecanicos')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'}>
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Mecanicos
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={mec} width={50} height={50} />
              </Grid>
              
          </Grid>
         
         
         
        </Grid>

        <Grid container spacing={1} style={{marginTop: '30px', marginBottom: '50px', cursor: 'pointer'}} justifyContent={'space-evenly'} >
          
        <Grid item container md={3} onClick={() => navigate('/incidencias')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'} >
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Incidencias
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={inc} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
          <Grid item container md={4} onClick={() => navigate('/usuarios')} style={{backgroundColor: '#e5f6fd',  borderRadius: '5px'}} justifyContent={'space-between'} alignItems={'center'}>
              
              <Grid item md={5}>
                <Typography variant="h5" >
                  Usuarios
                </Typography>
              </Grid>
              <Grid item md={5} >
              <img src={usu} width={50} height={50} />
              </Grid>
              
          </Grid> 
  
         
         
         
        </Grid>

        
      
    </Box>

   

    
      </ThemeProvider>
    </CacheProvider>
  );
}

export default Aeropuerto;

